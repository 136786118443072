import { SortDirection } from "common/interfaces/IPageResponse";
import { ICustomerContactPersonSortFields, ICustomerContactPerson } from "components/CustomerContactPersons/models";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import CompanyService from "services/company.service";
import CustomerContactPersonsService from "services/customer-contact-persons.service";
import ElectricityAgreementContentBlock from "../ElectricityAgreementContentBlock";
import NewElectricityAgreementCustomerForm from "./NewElectricityAgreementCustomerForm";
import NewElectricityAgreementDetailsForm from "./NewElectricityAgreementDetailsForm";
import NewElectricityAgreementMeteringPoints from "./NewElectricityAgreementMeteringPoints";
import NewElectricityAgreementPackageForm from "./NewElectricityAgreementPackageForm";
import NewElectricityAgreementPriceForm from "./NewElectricityAgreementPriceForm";
import NewElectricityAgreementBillingForm from "./NewElectrictyAgreementBillingForm";
import { getEarliestPossibleAgreementStartDate } from "helpers";
import { ElectricityAgreementForm, MeteringPoint } from "components/ElectricityAgreements/models";

interface NewElectricityAgreementDataProps {
    form: ElectricityAgreementForm;
    setForm: (form: ElectricityAgreementForm) => void;
    meteringPoints: MeteringPoint[];
    setMeteringPoints: any;
    isOffer: boolean;
}

const PERSONAL_CODE_LENGTH = 11;

export default function NewElectricityAgreementData(
    { form, setForm, meteringPoints, setMeteringPoints, isOffer }:
    NewElectricityAgreementDataProps
) {
    const [contactPersons, setContactPersons] = useState<ICustomerContactPerson[]>([]);
    const [customerLoading, setCustomerLoading] = useState(false);
    const [customerLoaded, setCustomerLoaded] = useState(false);

    const onCustomerSearch = () => {
        setCustomerLoaded(false);
        setCustomerLoading(true);
        CustomerContactPersonsService.getCustomerContactPersons({
            page: 0,
            size: 999,
            sortDirection: SortDirection.DESC,
            sortField: ICustomerContactPersonSortFields.REMOTE_CUSTOMER_ID,
            registryNumber: form.registryNumber
        }).then(res => {
            if (!res.empty) {
                let firstContact;
                if (form.registryNumber?.length === PERSONAL_CODE_LENGTH) {
                    firstContact = res.content.find(c => c.registryNumber === form.registryNumber);
                } else {
                    firstContact = res.content[0] as ICustomerContactPerson;
                }
                
                setForm({
                    ...form,
                    isCompany: !firstContact.isPerson,
                    customerId: firstContact.customerId,
                    customerName: firstContact.customerName,
                    vatRegistryNumber: firstContact.vatRegistryNumber,
                    address: firstContact.address,
                    city: firstContact.city,
                    zip: firstContact.zip,
                    county: firstContact.county,
                    bankAccountNumber: firstContact.bankAccountNumber,
                    eInvoice: firstContact.eInvoice,
                    contactPersonCode: firstContact.personalIdentificationCode,
                    contactId: res.content.length === 1 || firstContact.isPerson ? firstContact.id : undefined,
                    contactName: res.content.length === 1 || firstContact.isPerson ? firstContact.contactName : '',
                    email: res.content.length === 1 || firstContact.isPerson ? firstContact.contactEmail : '',
                    phone: res.content.length === 1 || firstContact.isPerson ? firstContact.phone || firstContact.mobileNumber : ''
                });
                setContactPersons(res.content);
                setCustomerLoaded(true);
                setCustomerLoading(false);
            } else {
                const initForm = {
                    registryNumber: form.registryNumber,
                    validated: false,
                    consolidatedInvoice: false,
                    eInvoice: false,
                    isCompany: form.registryNumber?.length !== PERSONAL_CODE_LENGTH,
                    sellCheck: false,
                    isJointInvoicePossible: false,
                    consumptionPackage: {}
                };
                setContactPersons([]);
                if (form.registryNumber!.length !== PERSONAL_CODE_LENGTH) {
                    CompanyService.getByRegistryNumber(form.registryNumber!).then(data => {
                        setForm({
                            ...initForm,
                            customerName: data.name,
                            address: data.address,
                            vatRegistryNumber: data.vatRegistryNumber
                        });
                        setCustomerLoaded(true);
                        setCustomerLoading(false);
                    }).catch(() => {
                        setForm(initForm);
                        setCustomerLoaded(true);
                        setCustomerLoading(false);
                    });
                } else {
                    setForm(initForm);
                    setCustomerLoaded(true);
                    setCustomerLoading(false);
                }
            }
        });
    }

    const earliestPossibleAgreementStartDate = getEarliestPossibleAgreementStartDate(meteringPoints);

    return (
        <Form className="new-electricity-agreement__container" noValidate validated={form.validated}>
            <ElectricityAgreementContentBlock title="Klient">
                <NewElectricityAgreementCustomerForm
                    form={form}
                    setForm={setForm}
                    isCompany={!!form.isCompany}
                    isFormDisabled={false}
                    showConsolidatedInvoiceCheckbox={meteringPoints.length > 1}
                    onSearch={onCustomerSearch}
                    contactPersons={contactPersons}
                    customerLoaded={customerLoaded}
                    customerLoading={customerLoading}
                />
                <div className="col-12 mt-3 px-0">
                    <Form.Check
                        id='check-sell-electricity'
                        custom
                        type="checkbox"
                        className='checkbox-large'
                        label='Paku tagasiostu võimalust'
                        onChange={() => setForm({...form, sellCheck: !form.sellCheck})}
                        checked={form.sellCheck}
                    />
                </div>
            </ElectricityAgreementContentBlock>
            { customerLoaded ? <>
                {
                    !isOffer && (
                        <ElectricityAgreementContentBlock title="Arveldamine">
                            <NewElectricityAgreementBillingForm 
                                form={form}
                                setForm={setForm}
                            />
                        </ElectricityAgreementContentBlock>
                    )
                }
                <ElectricityAgreementContentBlock title="Tarbimiskoht">
                    <NewElectricityAgreementMeteringPoints
                        meteringPoints={meteringPoints}
                        setMeteringPoints={setMeteringPoints}
                        form={form}
                        setForm={setForm}
                    />
                </ElectricityAgreementContentBlock>
                <ElectricityAgreementContentBlock title="Pakett">
                    <NewElectricityAgreementPackageForm 
                        form={form}
                        setForm={setForm}
                    />
                </ElectricityAgreementContentBlock>
                <ElectricityAgreementContentBlock title="Elektri müügihind kliendile">
                    <NewElectricityAgreementPriceForm 
                        form={form}
                        setForm={setForm}
                    />
                </ElectricityAgreementContentBlock>
                { form.sellCheck ? 
                    <ElectricityAgreementContentBlock title="Tagasiost">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Form.Label>Marginaal KM-ta (s/kWh)</Form.Label>
                                <Form.Control
                                    type="number"
                                    step={0.001}
                                    onChange={(e: any) => setForm({
                                        ...form,
                                        productionMarginal: e.target.value
                                    })}
                                />
                            </div>
                        </div>
                    </ElectricityAgreementContentBlock> : null}
                <ElectricityAgreementContentBlock title="Tagatis">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Form.Label>Tagatise summa (€)</Form.Label>
                            <Form.Control
                                type="number"
                                onChange={(e: any) => setForm({
                                    ...form,
                                    guarantee: e.target.value
                                })}
                            />
                        </div>
                    </div>
                </ElectricityAgreementContentBlock>
                <ElectricityAgreementContentBlock title="Leping">
                    <NewElectricityAgreementDetailsForm
                        form={form}
                        setForm={setForm}
                        isOffer={isOffer}
                        earliestPossibleAgreementStartDate={earliestPossibleAgreementStartDate}
                    />
                </ElectricityAgreementContentBlock>
            </> : null }
        </Form>

    )
}
